var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"RequirementPool"},[_c('a-tabs',{staticStyle:{"background":"#ffffff","padding":"24px 24px"},model:{value:(_vm.tabKey),callback:function ($$v) {_vm.tabKey=$$v},expression:"tabKey"}},[_c('a-tab-pane',{key:1},[_c('span',{staticClass:"tab-title",attrs:{"slot":"tab"},slot:"tab"},[_c('span',{staticClass:"title"},[_vm._v("待开发票")]),(_vm.toBeOpenCount>0)?_c('a-badge',{attrs:{"count":_vm.toBeOpenCount,"overflow-count":999,"number-style":{ backgroundColor: '#EE4261' }}}):_c('a-badge',{attrs:{"showZero":true,"count":"0","number-style":{
    backgroundColor: '#EFEFEF',
    color: 'rgba(0, 0, 0, .25)',
    boxShadow: '0 0 0 1px #d9d9d9 inset',
  }}})],1)]),_c('a-tab-pane',{key:2},[_c('span',{staticClass:"tab-title",attrs:{"slot":"tab"},slot:"tab"},[_c('span',{staticClass:"title"},[_vm._v("已开发票")]),(_vm.openedCount>0)?_c('a-badge',{attrs:{"count":_vm.openedCount,"overflow-count":999,"number-style":{ backgroundColor: '#EE4261' }}}):_c('a-badge',{attrs:{"showZero":true,"count":"0","number-style":{
    backgroundColor: '#EFEFEF',
    color: 'rgba(0, 0, 0, .25)',
    boxShadow: '0 0 0 1px #d9d9d9 inset',
  }}})],1)])],1),_c('div',{}),(_vm.tabKey===1)?_c('ToBeOpenInvoice',{on:{"count":_vm.setToBeOpenCount}}):_vm._e(),(_vm.tabKey === 2)?_c('OpenedInvoice',{on:{"count":_vm.setOpenedCount}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }