<template>
  <div class="infoCard">
    <div class="card-info-box" style="padding:0px 24px 24px 24px">
      <div class="statistics">
        <div class="statistics-part1">
          <span class="part-title">可开票金额</span>
          <span class="part-price" style="color: #FF6026" v-if="invoiceCountData">￥{{ invoiceCountData.totalEnableInvoiceAmount }}</span>
          <span class="part-price" style="color: #FF6026"v-else>￥0</span>
        </div>
        <div class="part-Symbol">
          <img src="../../../../assets/equal_to2.png">
        </div>
        <div class="statistics-part2">
          <span style="display: flex;justify-items: center;align-items: center">
            <span class="part-title">总计可开票金额</span>
            <a-tooltip placement="bottomLeft" :getPopupContainer="(node) => node.parentNode"
                       :arrowPointAtCenter="true">
            <template slot="title">
              <div class="container-tooltip">
                <div class="desc-item">所有订单包含：已开票、可开票、不可开票</div>
              </div>
            </template>
            <img src="/info.png" alt="" class="icon" />
          </a-tooltip></span>
          <span class="part-price" v-if="invoiceCountData">￥{{ invoiceCountData.totalPayAmount }}</span>
          <span class="part-price" v-else>￥0</span>
        </div>
        <img src="../../../../assets/subtract2.png">
        <div class="statistics-part3">
          <span class="part-title">历史已开票金额</span>
          <span class="part-price" v-if="invoiceCountData">￥{{ invoiceCountData.totalInvoicedAmount }}</span>
          <span class="part-price" v-else>￥0</span>
        </div>
        <img src="../../../../assets/subtract2.png">
        <div class="statistics-part4">
          <span style="display: flex;justify-items: center;align-items: center">
            <span class="part-title">不可开票金额</span>
            <a-tooltip placement="bottomLeft" :getPopupContainer="(node) => node.parentNode" :arrowPointAtCenter="true">
            <template slot="title">
              <div class="container-tooltip">
                <div class="desc-item">包含订单未完成金额</div>
              </div>
            </template>
            <img src="/info.png" alt="" class="icon" />
          </a-tooltip>
          </span>
          <span class="part-price"v-if="invoiceCountData">￥{{ invoiceCountData.totalDisableInvoiceAmount }}</span>
          <span class="part-price" v-else>￥0</span>
        </div>

      </div>

      <div class="searchInfo">
        <a-input
          v-model="queryParams.keywords"
          allowClear
          placeholder="请输入订单编号、订单名称、开票企业或其他关键词"
          style="width: 480px; height: 38px !important; line-height: 38px"
        />
        <div class="option-area">
          <a-button type="primary" @click="handleSearch" style="width: 81px">查询</a-button>
          <a-button @click="resetData" style="width: 81px">重置</a-button>
        </div>
      </div>
    </div>
    <div class="card-info-box" style="margin-top: 24px;">
      <div class="title-tag" v-if="invoiceTitle==null">
        <span style="align-items: center;display: flex;"><img src="../../../../assets/warning.png"><span
          style="padding-left: 8px">您尚未设置有效的开票信息，无法开票，请先设置发票抬头</span></span>
        <div style="color: #FF6026;" @click="toSetInvoiceTitle">设置发票抬头</div>
      </div>
      <div v-else-if="totalInvoiceAmount==0"
           style="flex-direction: row;display: flex;align-items: center;text-align:center; justify-content: flex-end;padding-bottom: 16px">

        <span style="color: #333333;padding-left: 24px">已选总开票金额 <spam style="color: #FF6026"> -</spam> </span>
        <a-button type="primary" disabled="disabled"
                  style="padding: 8px 24px;margin-left: 24px;display: flex;align-items: center;background-color:  #EFEFEF;color:#BFBFBF;align-items: center;
">
          下一步
        </a-button>
      </div>
      <div v-else-if="totalInvoiceAmount>0"
           style="flex-direction: row;display: flex;align-items: center;justify-content: flex-end;text-align: center;padding-bottom: 16px">
        <span style="color: #333333">已选择 <spam
          style="color: #FF6026">{{ ` ${selectedRowKeys.length} `
          }}</spam> 个订单</span>
        <span style="color: #333333;align-items: center;padding-left: 24px;display: flex">已选总开票金额 <spam
          style="color: #FF6026;font-weight: 600;font-size: 20px;padding-left: 8px"> ￥{{ totalInvoiceAmount
          }}</spam> </span>
        <a-button type="primary" style="padding: 8px 24px;margin-left: 24px;display: flex;align-items: center"
                  @click="handApply">
          下一步
        </a-button>
      </div>
      <a-table
        :columns="columns"
        :loading="loading"
        :data-source="dataSource"
        :pagination="false"
        style="margin-bottom: 16px"
        :scroll="{ x: 'max-content' }"
        rowKey="orderNo"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange,getCheckboxProps: record => ({
          props: {
            disabled: record.enableInvoiceAmount <=0, // Column configuration not to be checked
          },
        }) }"
      >
        <template slot="name" slot-scope="text">
          <a>{{ text }}</a>
        </template>
        <template slot="enableInvoiceAmount" slot-scope="text, record">
          <span style="color:#FF6026;">
            {{ text }}
          </span>
        </template>
        <template slot="invoicedAmount" slot-scope="text, record">
          <span style="color:#05C29C;">
            {{ text }}
          </span>
        </template>

      </a-table>
      <ZPagination
        :total="pageNation.total"
        :current="pageNation.pageNum"
        :pageSize="pageNation.pageSize"
        @handlePageChange="handleChangePage"
      />
    </div>
    <ApplyInvoiceDrawer ref="invoiceInfo" @ok="handleApply()" :invoiceTitle="invoiceTitle" :orderList="selectedOrderList" />
  </div>
</template>

<script>
import ZPagination from '@/components/plugins/ZPagination.vue'
import EmptyArea from '@/components/plugins/EmptyArea.vue'
import ListMixin from '@/mixins/listViewLoader'
import { mapGetters } from 'vuex'
import { postAction, getAction, uploadAction } from '@/api/manage'
import checkboxSearch from '@/components/plugins/checkboxSearch.vue'
import radioGroup from '@/components/plugins/radioGroup.vue'
import ApplyInvoiceDrawer from '@/views/invoice/purchaser/modules/applyInvoiceDrawer.vue'
import { getCurBusiness } from '@/util/utils'

export default {
  name: 'ContractManagement',
  mixins: [ListMixin],
  components: {
    ZPagination,
    EmptyArea,
    checkboxSearch,
    radioGroup,
    ApplyInvoiceDrawer
  },

  data() {
    return {
      selectedRowKeys: [],
      selectedOrderList: [],
      showMore: false,
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
      queryParams: {
        status: undefined
      },
      rangeDate: [],
      columns: [
        {
          title: '订单编号 ',
          dataIndex: 'orderNo',
          key: 'orderNo',
          width: 218
        },
        {
          title: '订单名称 ',
          dataIndex: 'orderTitle',
          key: 'orderTitle',
          width: 180,
          scopedSlots: { customRender: 'orderTitle' }
        },

        {
          title: '订单类型',
          dataIndex: 'paymentMethodDictName',
          key: 'paymentMethodDictName',
          width: 180,
          scopedSlots: { customRender: 'paymentMethodDictName' }
        },
        {
          title: '开票企业',
          dataIndex: 'supplierBusinessName',
          // align: 'center',
          key: 'supplierBusinessName',
          width: 240,
          scopedSlots: { customRender: 'supplierBusinessName' }
        },
        {
          title: '应付金额 ',
          dataIndex: 'orderAmount',
          key: 'orderAmount',
          width: 140,
          // align: 'center',
          scopedSlots: { customRender: 'orderAmount' }
        },
        {
          title: '已付金额 ',
          dataIndex: 'payAmount',
          key: 'payAmount',
          width: 100,
          scopedSlots: { customRender: 'payAmount' }
        },
        {
          title: '可开金额  ',
          dataIndex: 'enableInvoiceAmount',
          key: 'enableInvoiceAmount',
          width: 100,
          scopedSlots: { customRender: 'enableInvoiceAmount' }

        }, {
          title: '已开金额 ',
          dataIndex: 'invoicedAmount',
          key: 'invoicedAmount',
          width: 100,
          scopedSlots: { customRender: 'invoicedAmount' }
        }, {
          title: '不可开金 ',
          dataIndex: 'disableInvoiceAmount',
          key: 'disableInvoiceAmount',
          width: 100,
          scopedSlots: { customRender: 'disableInvoiceAmount' }
        }
      ],
      quoteOptions: [
        {
          dictKey: '1',
          dictValue: '待生效'
        },
        {
          dictKey: '2',
          dictValue: '进行中'
        },
        {
          dictKey: '3',
          dictValue: '已过期'
        }
      ],
      otherOptions: [
        {
          dictKey: '0',
          dictValue: '本月'
        },
        {
          dictKey: '3',
          dictValue: '三个月'
        },
        {
          dictKey: '4',
          dictValue: '近半年'
        }
      ],
      mainOptions: [],
      activeInfo: {},
      requestType: 'post',
      url: {
        list: '/v1/invoice/invoiceOrderList'
      },
      statusDictList: {
        2: '进行中',
        1: '待生效',
        3: '已过期',
        4: '已终止'
      },
      statusDictStyle: {
        2: 'status_4',
        1: 'status_2',
        3: 'status_10',
        4: 'status_10'
      },
      statusADictList: {
        0: '待生效',
        1: '待签署',
        2: '已签署 '
      },
      //  0:发起 1:签署中 2 签署完成
      statusADictStyle: {
        0: 'status_1',
        1: 'status_6',
        2: 'status_4'
      },
      createTimeScoped: '',
      createTimeRange: [],
      orderNo: null,
      disableMixinCreated: true,
      totalInvoiceAmount: 0,
      invoiceTitle: null,
      invoiceCountData: {
        totalDisableInvoiceAmount: 0,
        totalEnableInvoiceAmount: 0,
        totalInvoicedAmount: 0,
        totalPayAmount: 0
      }
    }
  },
  created() {
    this.dataSource = [{ name: '11', phone: '18612983759' }, {}, {}]
    this.handleLoadData(1)
    this.getInvoiceTitle()
    this.getInvoiceCount()
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0
    }
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    handleChangeKind({ type, record }) {
      if (type == 1) {
        console.log(record, 111)
        this.$refs.signContractInfo.handleOpen(record)
      } else {
        this.handleUploadContract(record)
      }
    },
    handleChangeType({ record }) {
      this.$refs.UploadContract.handleOpen(record)

    },
    handleEmitContract(e) {
      this.$refs.signContractInfo.handleOpen(e)
    },
    handleViewInfo(item) {
      this.$refs.viewContract.handleOpen(item)
    },
    handleChangeRadio(e, key) {
      if (e != '-1') {
        this[`${key}Range`] = []
      }
    },
    handleChangeDate(e, key) {
      if (e && e.length) {
        this[key] = -1
      }
    },
    handleInit() {
      getAction('/quote/dictionary').then((res) => {
        const { success, data } = res
        success && (this.mainOptions = data)
      })
    },
    handleJump(url) {
      window.open(url)
    },
    handleFileUpload(e) {
      let selectedFile = event.target.files[0]
      let formData = new FormData()
      formData.append('file', selectedFile)
      formData.append('dir', 'images')
      uploadAction('/file/images/upload', formData).then((res) => {
        const { success, message, data } = res
        if (success) {
          // 触发签单接口
          postAction('/v1/contract/framework/offline/sign', {
            contractFile: data.url,
            contractNo: this.activeInfo.contractNo || ''
          }).then((res) => {
            const { success, message } = res
            if (success) {
              this.reaLoad()
            } else {
              this.$message.error(message)
            }
          })
        } else {
          this.$message.error(message)
        }
      })
    },
    handleUploadContract(item) {
      this.$refs.newUploader.handleOpen(item)
    },
    handleSearch() {
      if (this.rangeDate.length) {
        const [submitStartDate, submitEndDate] = this.rangeDate
        this.queryParams['submitStartDate'] = submitStartDate
        this.queryParams['submitEndDate'] = submitEndDate
      }
      this.handleLoadData(1)
    },
    getInvoiceTitle() {
      console.log(this.setUserInfo())
      const payload = {}
      payload.businessId = getCurBusiness().businessId
      getAction('/business/invoiceHead/queryByBusinessId', payload).then((res) => {
        const { success, data } = res
        if (success) {
          this.invoiceTitle = data
        }
      })
    },
    getInvoiceCount() {
      const payload = { current: 1, size: 10 }
      payload.purchaseBusinessId = getCurBusiness().businessId
      postAction('/v1/invoice/invoiceCountAmount', payload).then((res) => {
        const { success, data } = res
        if (success) {
          this.invoiceCountData = data
        }
      })
    },

    handleLoadData(flag) {
      if (flag) {
        this.pageNation = {
          pageNum: 1,
          pageSize: 10,
          total: 0
        }
      }
      const { startDate, endDate, keywords } = this.queryParams
      let statusList = [], params = { keywords, startDate, endDate }
      if (!this.url && !this.url.list) return this.$message.warning('请设置请求地址')
      let pageParams = { ...this.pageNation }
      delete pageParams.total
      this.loading = true
      this.dataSource = []
      const { pageSize, pageNum } = pageParams
      let api = this.requestType == 'get' ? getAction : this.requestType == 'post' ? postAction : postQueryAction
      api(this.url.list, {
        statusList,
        size: pageSize,
        current: pageNum,
        ...params
      })
        .then((res) => {
          const { success, data } = res
          this.loading = false
          if (success) {
            const { total, list } = data
            this.dataSource = list
            this.pageNation['total'] = total
          }
        })
        .catch((e) => {
          this.loading = false
        })
    },
    reaLoad() {
      this.pageNation = {
        pageNum: 1,
        pageSize: 10,
        total: 0
      }
      this.queryParams = {}
      this.createTimeRange = []
      this.dataSource = []
      this.handleLoadData(1)
    },

    start() {
      this.loading = true
      // ajax request after empty completing
      setTimeout(() => {
        this.loading = false
        this.selectedRowKeys = []
      }, 1000)
    },
    onSelectChange(selectedRowKeys, selectedRowData) {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      console.log('selectedRowData changed: ', selectedRowData)
      this.selectedRowKeys = selectedRowKeys
      this.totalInvoiceAmount = selectedRowData.reduce((sum, item) => {
        return sum + item.enableInvoiceAmount
      }, 0).toFixed(2)
      this.selectedOrderList = selectedRowData
    },
    getCheckboxProps(record) {
      return {
        props: {
          disabled: record.enableInvoiceAmount <= 0, // Column configuration not to be checked
          name: record.name
        }
      }
    }
    , resetData() {
    }, handApply() {
      this.$refs.invoiceInfo.handleOpen({})
    },
    //去发票抬头页面
    toSetInvoiceTitle() {
      this.$emit('toInvoiceTitle')
    },
    handleApply() {
      this.handleLoadData(1)
      this.getInvoiceCount()
      this.totalInvoiceAmount = 0
      this.selectedRowKeys = []
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .ant-table {
  color: rgba(0, 0, 0, 0.85);
}

// ::v-deep .ant-table-column-title {
//   font-size: 16px;
// }

::v-deep .ant-table-thead {
  tr {
    background: #f4f5f7 !important;

    th {
      border-bottom: none;
      font-size: 14px;
      font-weight: 600 !important;
      color: #131212;
    }
  }
}

::v-deep .ant-table-tbody {
  tr {
    // &:not(:last-of-type)>td {
    //   border: none;
    // }

    & > td {
      border-bottom: 1px solid #efefef;
    }
  }
}

::v-deep .ant-form-item-control {
  line-height: 30px;
}

::v-deep .ant-form-item-label {
  line-height: 30px;
}

::v-deep .ant-table-thead > tr > th {
  background: #efefef;
  border-bottom: none;
  font-weight: 500;
}

.searchInfo {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .option-area {
    margin-left: 8px;

    .ant-btn {
      width: 97px;
      height: 38px;

      & + .ant-btn {
        margin-left: 8px;
      }
    }
  }
}

.statistics {
  display: flex;
  flex-direction: row;
  //text-align: center;
  align-items: center;
  justify-content: space-between;
  //margin-left: 288px;
  //padding: 0px 60px 30px 60px;
  padding-bottom: 24px;
}

.statistics-part1,
.statistics-part2,
.statistics-part3,
.statistics-part4 {
  font-family: 'Arial-BoldMT', 'Arial Bold', 'Arial', sans-serif;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-image: url("../../../../assets/open_amount.png");
  background-size: cover;
  width: 230px;
  height: 72px;
  padding: 12px 12px;
}

.statistics-part2 {
  background-image: url("../../../../assets/total_open_amount.png");
}

.statistics-part3 {
  background-image: url("../../../../assets/opened_amount.png");
}

.statistics-part4 {
  background-image: url("../../../../assets/not_open_amount.png");
}


@import '~@/styles/list.less';

::v-deep .ant-form-item-label {
  width: 67px !important;
}

.fileUploader {
  display: none;
}

.pickerDict {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: max-content;
  min-width: 140px;
  height: 26px;
  color: #131212;
  cursor: pointer;
  padding: 0 6px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;

  .placeholder {
    color: #999;
  }
}

.row_title {
  display: flex;
  align-items: center;
}

img.newIcon {
  width: 16px;
  margin-left: 6px;
}

.inline-name {
  width: 100%;
  display: flex;
  align-items: center;

  span.label {
    display: inline-block;
    width: 120px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

span.tag {
  display: inline-block;
  width: 32px;
  height: 20px;
  border-radius: 3px;
  font-size: 12px;
  color: #fff;
  line-height: 20px;
  text-align: center;
  background: #05c29c;
  margin-right: 6px;

  &.danger {
    background: #ee4261;
  }
}

.part-Symbol {
  font-size: 21px;
  font-weight: bold;
}

.part-title {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  height: 20px;
  color: #5E5E66;

}

.part-price {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: #131212;
  padding-top: 4px;;
}

img.icon {
  width: 12px;
  cursor: pointer;
  //margin-left: 4px;
}

.card-bottom-box {
  border-top: 1px solid #efefef;
  margin-bottom: 16px;
  //margin-top: 20px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #909090;
  align-items: center
}

.title-tag {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 8px 10px 24px;
  background: rgba(255, 125, 47, 0.08);
  border-radius: 4px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: #262626;
  margin-bottom: 18px;
}
::v-deep .ant-input{
  height: 38px !important;
}
</style>
