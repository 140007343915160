<template>
  <DrawerView :visible="visible" :width="1200" :title="'开票信息'" show-slot-title @cancel="handleCancel">

    <div class="invoiceDetails">
      <div class="invoice-content">
        <div class="RequirementConfirmation-content-item" style="margin-bottom: 15px">
          <h2 class="RequirementConfirmation-title">
            <span class="title">购买方信息</span>
            <a class="action">
            </a>
          </h2>
        </div>
        <a-descriptions :column="2" bordered style="margin-bottom: 25px;" v-if="invoiceData.businessInvoiceHeadDTO ">
          <a-descriptions-item label="发票抬头">
            {{ invoiceData.businessInvoiceHeadDTO.headType == 1 ? '个人' : invoiceData.businessInvoiceHeadDTO.headDesc
            }}
          </a-descriptions-item>
          <a-descriptions-item label="发票类型">
            {{ invoiceData.businessInvoiceHeadDTO.invoiceType == 1 ? '增值税普通发票（电子发票）' : '增值税专用发票（电子发票）'
            }}
          </a-descriptions-item>

            <a-descriptions-item label="统一社会信用代码 / 纳税人识别号" v-if="invoiceData.businessInvoiceHeadDTO.headType == 2"
            >
              {{ invoiceData.businessInvoiceHeadDTO.creditCode?invoiceData.businessInvoiceHeadDTO.creditCode:'-' }}
            </a-descriptions-item>
            <a-descriptions-item label="地址" v-if="invoiceData.businessInvoiceHeadDTO.headType == 2">
              {{ invoiceData.businessInvoiceHeadDTO.registerAddress?invoiceData.businessInvoiceHeadDTO.registerAddress:'-' }}
            </a-descriptions-item>
            <a-descriptions-item label="开户银行名称" v-if="invoiceData.businessInvoiceHeadDTO.headType == 2">
              {{ invoiceData.businessInvoiceHeadDTO.invoiceBankName ? invoiceData.businessInvoiceHeadDTO.invoiceBankName:'-'}}
            </a-descriptions-item>
            <a-descriptions-item label="基本开户账户" v-if="invoiceData.businessInvoiceHeadDTO.headType == 2">
              {{ invoiceData.businessInvoiceHeadDTO.invoiceBankAccount?invoiceData.businessInvoiceHeadDTO.invoiceBankAccount:'-' }}
            </a-descriptions-item>

        </a-descriptions>
      </div>
    </div>
    <div class="invoice-content">
      <div class="RequirementConfirmation-content-item" style="margin-bottom: 15px">
        <h2 class="RequirementConfirmation-title" style="margin-bottom: 0px">
          <span class="title">销售方信息</span>
          <a class="action">
          </a>
        </h2>
        <div class=" card-info-box sale-table" v-if="invoiceData.orderInvoiceDTO">
          <div style="background-color: #F4F5F7;display: flex;justify-content: space-between;padding: 8.5px 16px">
            <div class="saleName">
              销售方名称：{{ invoiceData.orderInvoiceDTO.supplierBusinessName }}
            </div>
            <!--            <a @click="saleInfoDelete(record)">删除</a>-->
          </div>
          <div class="saleTitleGroup">
            <div class="saleTitle" style="border-left: 0px">开票内容</div>
            <div class="saleTitle">规格型号</div>
            <div class="saleTitle">单位</div>
            <div class="saleTitle">数量</div>
            <div class="saleTitle">税率</div>
            <div class="saleTitle">金额(元)</div>
          </div>
          <div class="saleTitleGroup">
            <div class="salePart" style="border-left: 0px">{{ invoiceData.orderInvoiceDTO.invoiceContent }}
            </div>
            <div class="salePart">{{ invoiceData.orderInvoiceDTO.model ? invoiceData.orderInvoiceDTO.model : '-' }}
            </div>
            <div class="salePart">{{ invoiceData.orderInvoiceDTO.unit }}</div>
            <div class="salePart">{{ invoiceData.orderInvoiceDTO.number }}</div>
            <div class="salePart">{{ invoiceData.orderInvoiceDTO.tax ? invoiceData.orderInvoiceDTO.tax * 100 + '%' : '-'
              }}
            </div>
            <div class="salePart" style="color: #FF6026;">{{ invoiceData.orderInvoiceDTO.amount }}
            </div>
          </div>
          <div class="saleTitleGroup" style="height: 56px">
            <div class="saleTitle"
                 style="background: #F4F5F7;width: 19.5%; border-left: 0px;border-bottom: 0px;align-items: center">发票备注
            </div>
            <div class="salePart" style="border-bottom: 0px;display: flex;padding: 9px 12px;align-items: center">
              <a-form-model :model="formBaseInfo" :label-col="labelCol" :wrapper-col="wrapperCol" style="flex: 1">
                <a-form-model-item label="" style="margin-bottom: 0px !important;">
                  <a-input style="width: 100%;" allow-clear v-model="invoiceData.orderInvoiceDTO.remark"
                  ></a-input>
                </a-form-model-item>
              </a-form-model>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="RequirementConfirmation-content-item" style="margin-bottom: 15px">
      <h2 class="RequirementConfirmation-title">
        <span class="title">发票关联订单</span>
        <a class="action">
          <!--            <img src="~@/assets/export_icon.png" alt="" />-->
        </a>
      </h2>
    </div>
    <a-table v-if="invoiceData.orderInvoiceDTO"
      :columns="columns"
      :data-source="invoiceData.orderInvoiceDTO.orderInvoiceRelations"
      :pagination="false"
      style="margin-bottom: 16px"
      :scroll="{ x: 'max-content' }"
      rowKey="invoiceId"
    >
      <template slot="name" slot-scope="text">
        <a>{{ text }}</a>
      </template>

    </a-table>
    <ZPagination
      :total="pageNation.total"
      :current="pageNation.pageNum"
      :pageSize="pageNation.pageSize"
      @handlePageChange="handleChangePage"
    />

    <a-modal title="发票作废确认" :visible="visibleDel" centered @ok="handleOk(1)" @cancel="handleCancel">
      <div class="info-card">
        <img src="/warning.png" alt="" />
        <span>确认删除该订单发票信息？</span>
      </div>
    </a-modal>

    <a-modal title="上传发票" :visible="uploadVnioicevisible" centered @ok="submitInvoice(1)"
             @cancel="uploadVnioicevisible=false">
      <div class="info-card" style="display: flex;text-align: center;justify-content: center">
        <a-form-model
          ref="formFile"
          :colon="false"
          :model="formFile"
          style="margin-top: 10px"
        >
          <a-form-model-item :label-col="{ span: 2 }" :wrapper-col="{ span: 19 }">
            <div class="uploadCover">
              <div style="width: 334px">
                <UploadDrag :number="10" v-model="invoiceFileList" :accept="'.pdf'" />
                <div class="endTitle">请上传电子发票-pdf文件</div>
              </div>
            </div>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
    <template v-slot:btnArea>
      <a-button @click="handleCancel">取消</a-button>
      <a-button @click="openInvoice()" :loading="loading" type="primary">开票</a-button>
    </template>
  </DrawerView>
</template>
<script>
import { defineComponent } from 'vue'
import DrawerView from '@/components/plugins/drawerView.vue'
import JDictSelect from '@/components/plugins/JDictSelect.vue'
import { getAction, postAction } from '@/api/manage'
import uploadFile from '@/components/plugins/uploadFile.vue'
import UploadDrag from '@/components/plugins/uploadDrag.vue'
import { mapGetters } from 'vuex'
import ZPagination from '@/components/plugins/ZPagination.vue'

export default defineComponent({
  components: { UploadDrag, uploadFile, JDictSelect, DrawerView,ZPagination}
  ,
  data() {
    return {
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
      formBaseInfo: {},
      formFile: {},
      pageNation: {
        pageNum: 1,
        pageSize: 20,
        total: 0
      },
      orderDataSourceModel: [{
        orderNo: '2312312',
        orderName: '12312312',
        orderType: '预付全款',
        compayName: '上海我是供应有限公司',
        payable: '19890.00'
      }],
      columns: [
        {
          title: '订单编号 ',
          dataIndex: 'orderNo',
          key: 'orderNo',
          width: 218
        },
        {
          title: '订单名称 ',
          dataIndex: 'orderTitle',
          key: 'orderTitle',
          width: 180,
          scopedSlots: { customRender: 'orderTitle' }
        },

        {
          title: '开票款项',
          dataIndex: 'paymentMethodDictName',
          key: 'paymentMethodDictName',
          width: 180,
          scopedSlots: { customRender: 'paymentMethodDictName' }
        },
        {
          title: '开票企业',
          dataIndex: 'supplierBusinessName',
          // align: 'center',
          key: 'supplierBusinessName',
          width: 240,
          scopedSlots: { customRender: 'supplierBusinessName' }
        },
        {
          title: '开票金额 ',
          dataIndex: 'invoiceAmount',
          key: 'invoiceAmount',
          width: 140,
          scopedSlots: { customRender: 'invoiceAmount' }
        }],
      visibleDel: false,
      visible: false, saleColumns: [
        {
          title: '开票内容',
          dataIndex: 'num',
          width: 176
        },
        {
          title: '规格型号',
          dataIndex: 'prePrice',
          scopedSlots: { customRender: 'prePrice' },
          width: 176
        },
        {
          title: '单位',
          dataIndex: 'unit',
          width: 176,
          scopedSlots: { customRender: 'unit' }
        },
        {
          title: '数量',
          dataIndex: 'total',
          width: 176,
          scopedSlots: { customRender: 'total' }
        }, {
          title: '税率',
          dataIndex: 'taxRate',
          width: 176,
          scopedSlots: { customRender: 'taxRate' }
        }, {
          title: '金额(元)',
          dataIndex: 'price',
          width: 176,
          scopedSlots: { customRender: 'price' }
        }
      ], dataSourceModel: [{
        num: '包装采购费',
        prePrice: '-',
        unit: '次',
        total: '1',
        taxRate: '17%',
        price: '200.00'
      }],
      uploadVnioicevisible: false,
      invoiceData: {},
      targetVoidId: null,
      loading: false,
      invoiceFileList: []
    }
  },
  created() {
  },
  methods: {
    ...mapGetters(['dataSource']),
    handleOpen(item) {
      console.log(item)
      this.visible = true
      this.targetVoidId = item.id
      this.invoiceFileList = []
      this.handleLoadData()
    },
    handleCancel() {
      this.visible = false
    }, saleInfoDelete() {
      this.visibleDel = true
    }, openInvoice() {
      this.uploadVnioicevisible = true
    },
    handleChangePage({ currentPage, pageSize }) {
      this.pageNation.pageNum = currentPage
      this.pageNation.pageSize = pageSize
      this.handleLoadData()
    },
    handleLoadData() {
      getAction('/v1/invoice/invoiceDetail', {
        id: this.targetVoidId
      }).then((res) => {
        const { success, data, message } = res
        if (success) {
          this.invoiceData = data
        } else {
          this.$message.warning(message)
        }
      })
    },
    submitInvoice() {
      if (this.invoiceFileList.length == 0) {
        this.$message.error('请上传电子发票')
        return
      }

      const invoiceFile = this.invoiceFileList.map((item) => item.url || '').toString()
      getAction('/v1/invoice/invoiceInvoiced', {
        id: this.targetVoidId,
        invoiceFile
      }).then((res) => {
        const { success, data, message } = res
        if (success) {
          this.$message.success('开票成功')
          this.$emit('ok')
          this.visible = false
          this.uploadVnioicevisible = false
        } else {
          this.$message.warning(message)
        }
      })

    }

  }
})

</script>
<style lang="less" scoped>
.invoiceDetails {
  box-sizing: border-box;
  background-color: #ffffff;
}

.RequirementConfirmation {
  .header {
    &-title {
      color: #090b12;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 8px;
    }

    &-subtitle {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
      margin-bottom: 15px;

      .copy {
        font-size: 13px;
        margin-left: 8px;
        font-weight: 500;
      }
    }
  }

  &-title {
    font-size: 15px;
    color: #605f5f;
    margin-bottom: 24px;
    height: 38px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #efefef;
    position: relative;

    &:before {
      background: #FF6026;
      display: inline-block;
      content: '';
      width: 5px;
      height: 16px;
      margin-right: 8px;
    }

    .title {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #000;
      margin-right: 10px;
    }

    .subTitle {
      font-weight: 400;
      font-size: 14px;
      color: #8d8e99;
    }
  }

  &-content {
    &-item {
      width: 100%;
      background: #fff;
      position: relative;

      .edit-btn {
        position: absolute;
        top: 4px;
        right: -15px;

        img {
          width: 15px;
          margin-right: 5px;
        }
      }
    }
  }

  ::v-deep .ant-form-item-label {
    line-height: 22px;

    label {
      color: #626161 !important;
    }
  }

  ::v-deep .ant-form-item-control {
    line-height: 22px;
    color: #131212;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .subTitle {
    width: calc(100% - 40px);
    font-size: 15px;
    color: #FF6026;
    padding-bottom: 8px;
    border-bottom: 1px solid #efefef;
    margin: 0 auto 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .tag-title {
      padding: 2px 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      background: #fff2ea;
      border-radius: 2px;
    }

    img {
      width: 15px;
      margin-right: 4px;
    }

    span {
      line-height: 22px;
      font-size: 14px;
    }
  }

  .basicInfo-form {
    background: #f7f8fa;
    border-radius: 8px;
    padding: 18px 0 4px;

    &:not(:last-of-type) {
      margin-bottom: 30px;
    }

    &-item {
      padding: 0 20px;
    }
  }

  ::v-deep .ant-form-item {
    margin-bottom: 20px;
  }

  .ant-btn-link[disabled] img {
    filter: grayscale(100%);
  }

  .price-title {
    width: 107px;
    margin-top: 8px;
  }

  .process-content {
    background: #f7f8fa;
    border-radius: 8px;
    padding: 24px;

    ::v-deep .ant-timeline-item-tail {
      border: 0.5px dashed #dcdcdc;
    }

    ::v-deep .ant-timeline-item-head {
      width: 5px;
      height: 5px;
      background: #d7d7db;
      border: none;
      left: 2px;
      top: 3px;
    }

    .content {
      padding-top: 5px;
      color: #000000;
      font-size: 14px;
      line-height: 20px;
    }

    .time {
      font-size: 14px;
      line-height: 20px;
      color: #8d8e99;
    }
  }
}

.ant-descriptions {
  // background-color: #f7f8fa;
  // padding: 16px 25px 0;
  // border-radius: 8px;

  &.tag_2 {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

::v-deep .ant-descriptions-item-label {
  color: #595959;
}

::v-deep .ant-descriptions-item-content {
  color: #131212;
}

::v-deep .ant-radio-disabled + span {
  color: #262626;
}

.card-view {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 25px 25px;

  &-item {
    width: calc((100% - 50px) / 3);
    height: 144px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px dashed #e8e9eb;
    padding: 15px;
    display: flex;
    position: relative;

    .cover {
      width: 112px;
      height: 112px;
      border-radius: 4px;
      object-fit: cover;
      margin-right: 15px;
      cursor: pointer;

      &:hover {
        & + .option-area {
          opacity: 1;
          z-index: 10;
        }
      }
    }

    .option-area {
      width: 112px;
      height: 112px;
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.65);
      position: absolute;
      left: 15px;
      top: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      z-index: -1;

      &:hover {
        opacity: 1;
        z-index: 10;
      }

      img {
        height: 16px;
        cursor: pointer;
      }

      .split-line {
        width: 1px;
        height: 14px;
        background-color: #fff;
        margin: 0 15px;
      }
    }
  }

  .info-card {
    &.inside {
      flex: 1 0 0;
      min-width: 0;
    }

    .sub_title {
      font-size: 14px;
      font-weight: 400;
      color: #131212;
      line-height: 22px;
      margin-bottom: 8px;
      overflow: hidden;
      .text-ellipsis-multi(2);
    }

    div.labels {
      font-size: 12px;
      color: #8d8e99;
      line-height: 20px;
      font-weight: 400;
    }
  }
}

.desc-title {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #efefef;
  padding-bottom: 12px;
  margin-bottom: 15px;

  span.title {
    font-size: 14px;
    color: #131212;
  }
}

div.file-card {
  width: max-content;
  height: 64px;
  background: #fff;
  border-radius: 2px;
  padding: 8px 12px 8px 12px;
  margin-bottom: 8px;

  &-title {
    margin-bottom: 15px;

    img.icon {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }

    .fileName {
      color: #1d2129;
      font-weight: 400;
      font-size: 14px;
    }
  }

  &-size {
    font-weight: 400;
    color: #8d8e99;
    height: 22px;
    line-height: 22px;
    font-size: 12px;
    padding-left: 22px;
  }
}

.coverBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.last-label {
  width: 100%;
  height: 102px;
  background-image: url('~@/assets/longerBG.png');
  border-radius: 8px;
  display: flex;
  align-items: center;
  position: relative;

  .ant-form-item {
    margin-bottom: 0;
  }

  ::v-deep .ant-input {
    border-color: #ff9a57;
  }

  ::v-deep .ant-input-suffix {
    color: #131212;
  }

  .moneyIcon {
    position: absolute;
    bottom: 0;
    right: 19px;
  }
}

.numberInput {
  width: 320px;
  border-color: #ff7d2f;
  height: 38px;
  line-height: 38px;

  ::v-deep .ant-input-number-input {
    font-size: 18px;
    color: #131212;
    font-weight: 500;
  }
}


@import '~@/styles/form.less';
@import '~@/styles/orderDetail.less';
.action {
  position: absolute;
  right: 0;
  top: 50%;
  font-size: 14px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;

  img {
    width: 20px;
    margin-right: 4px;
  }
}

::v-deep .ant-form-item-label {
  width: 95px !important;
  display: flex;
  text-align: right !important;
  justify-content: flex-end;
}

.sale-table {
  .quote-table {
    padding: 0 15px;
    overflow-x: auto;

    .ant-table-wrapper {
      width: 1056px;
    }

    ::v-deep .ant-table-thead > tr > th {
      background: #F4F5F7;
    }

    ::v-deep .ant-table-footer {
      height: 48px;
      padding: 0;

      .footer-info-bar {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 0 0 0 16px;
        box-sizing: border-box;

        .title {
          font-size: 14px;
          font-weight: 600;
          line-height: 22px;
        }

        .total {
          height: 100%;
          border-left: 1px solid #E8E9EB;
          width: 484px;
          line-height: 46px;
          padding-left: 16px;
          color: #FF6026;
          font-size: 16px;
        }
      }
    }
  }
}

::v-deep .ant-table-footer {
  //height: 56px !important;
  padding: 0px !important;
}

.orderno-tag {
  //padding: 9px 4px 9px 12px;
  //min-width: 136px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  gap: 10px;
  background: #E8E9EB;
  border-radius: 3px;
  white-space: nowrap;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  height: 20px;
  margin-right: 4px;
}

.saleName {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: #264AFF;
  /* 状态标签 */
  display: flex;
  flex-direction: row;
  //justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 4px;
  background: #E2E7FB;
  border-radius: 3px;

}

.saleTitleGroup {
  display: flex;

}

.saleTitle, .salePart {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.85);
  background: #F4F5F7;
  border: 1px solid #E8E9EB;
  width: 100%;
  padding: 10px 16px;

  font-family: 'PingFang SC';
  font-style: normal;
  font-size: 14px;
  line-height: 150%;
  /* 文字色/标题+主要 */
  color: rgba(0, 0, 0, 0.85);
  border-right: 0px;
}
.saleTitle{
  font-weight: bold;
}
.salePart {
  background-color: #ffffff;
  border-top: 0px;
  border-right: 0px;
}

.sale-table {
  border: 1px solid #E8E9EB;
  margin-top: 24px;
}

.openingInvocie {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  padding-right: 16px;
}

.info-card {
  font-size: 16px;
  color: #131212;
  display: flex;
  align-items: center;

  img {
    width: 21px;
    height: 21px;
  }

  span {
    margin-left: 10px;
  }
}

.uploadCover {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>

