<template>
  <div class="RequirementPool">
    <a-tabs v-model="tabKey" style="background: #ffffff;padding:24px 24px">
      <a-tab-pane :key="1">
        <span class="tab-title" slot="tab">
          <span class="title">待开发票</span>
          <a-badge :count="toBeOpenCount" :overflow-count="999"
                   :number-style="{ backgroundColor: '#EE4261' }" v-if="toBeOpenCount>0" />
          <a-badge v-else :showZero="true" count="0" :number-style="{
      backgroundColor: '#EFEFEF',
      color: 'rgba(0, 0, 0, .25)',
      boxShadow: '0 0 0 1px #d9d9d9 inset',
    }" />
        </span>
      </a-tab-pane>
      <a-tab-pane :key="2">
        <span class="tab-title" slot="tab">
          <span class="title">已开发票</span>
          <a-badge :count="openedCount" :overflow-count="999"
                   :number-style="{ backgroundColor: '#EE4261' }" v-if="openedCount>0" />
          <a-badge v-else :showZero="true" count="0" :number-style="{
      backgroundColor: '#EFEFEF',
      color: 'rgba(0, 0, 0, .25)',
      boxShadow: '0 0 0 1px #d9d9d9 inset',
    }" />
        </span>
      </a-tab-pane>
    </a-tabs>
    <div class=""></div>
    <ToBeOpenInvoice v-if="tabKey===1" @count="setToBeOpenCount"></ToBeOpenInvoice>
    <OpenedInvoice v-if="tabKey === 2" @count="setOpenedCount"></OpenedInvoice>
  </div>
</template>

<script>
import ZPagination from '@/components/plugins/ZPagination.vue'
import { mapGetters } from 'vuex'
import EmptyArea from '@/components/plugins/EmptyArea.vue'
import Notice from '@/mixins/notice'
import MessageLoader from '@/mixins/messageJumper'

import OpenedInvoice from '@/views/invoice/supplier/modules/openedInvoice.vue'
import ToBeOpenInvoice from '@/views/invoice/supplier/modules/toBeOpenInvoice.vue'
import { getAction } from '@/api/manage'

export default {
  name: 'messageManagement',
  components: {
    OpenedInvoice,
    ZPagination,
    EmptyArea,
    ToBeOpenInvoice
  },
  mixins: [Notice, MessageLoader],
  data() {
    return {
      tabKey: 1,
      toBeOpenCount: 0,
      openedCount: 0
    }
  },
  created() {
    this.handSupplierInvoiceCount()
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    setToBeOpenCount(count) {
      this.toBeOpenCount = count
      this.handSupplierInvoiceCount()
    },
    setOpenedCount(count) {
      this.openedCount = count
    },
    handSupplierInvoiceCount() {
      getAction('/v1/invoice/invoiceCount').then((res) => {
        const { success, data } = res
        if (success) {
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.RequirementPool {
  min-height: calc(100vh - 111px);
  background-color: #F9F9F9 !important;
  padding: 0px !important;
  //padding: 10px 25px 10px 24px;
  box-sizing: border-box;
  border-radius: 3px;

  &-searchBar {
    border-radius: 8px;
    padding-bottom: 8px;
    margin-bottom: 15px;

    .ant-btn {
      & + .ant-btn {
        margin-left: 8px;
        margin-right: 22px;
      }
    }

    ::v-deep .ant-form-item {
      margin-bottom: 0;
    }
  }


  .table-content {
    min-height: calc(100vh - 285px);

    ::v-deep .ant-table-placeholder {
      height: calc(100vh - 343px);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    // padding: 0 25px 0;
    .flexTitle {
      .flexLayout(@justifyContent: flex-start);
    }

    &-title,
    .table-row {
      .flexLayout();
      background: #f2f2f2;
      text-align: center;
      font-weight: bold;

      .title {
        width: 25%;
        text-align: left;

        .label {
          display: inline-block;
          margin-right: 6px;
          max-width: calc(100% - 58px);
          .text-emphasis();
        }

        .cover {
          width: 52px;
          height: auto;
        }
      }

      .kind {
        width: 20%;
      }

      .num {
        width: 13%;
      }

      .provider {
        width: 20%;
      }

      .action {
        width: 20%;

        .ant-btn {
          width: 100px;
        }
      }
    }

    &-title {
      padding: 20px 16px 20px 40px;
      margin-bottom: 15px;
      font-size: 13px;

      .title {
        text-align: center;
      }
    }

    ::v-deep .ant-collapse {
      background: #fffff8;
      border: none;
    }

    ::v-deep .ant-collapse-item {
      background: #f2f2f2;
      margin-bottom: 15px;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      overflow: hidden;
    }

    ::v-deep .ant-form-item {
      margin-bottom: 6px;
    }
  }

  ::v-deep .ant-tabs-tab {
    padding: 12px 0;

    .tab-title {
      display: flex;
      align-items: center;
    }

    span.title {
      font-size: 16px;
      margin-right: 8px;
      font-weight: 500;
    }
  }
}

.top-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  ::v-deep .ant-select-lg {
    font-size: 14px;
  }

  ::v-deep .ant-input-lg {
    font-size: 14px;
  }

  .ant-btn {
    width: 97px;
    height: 38px;
    color: rgba(0, 0, 0, 0.65);
    font-weight: 500;
    margin-right: 8px;

    &:not(:last-of-type):hover {
      color: #ff9a57;
    }

    & + .ant-btn {
      color: #fff;
    }
  }
}

::v-deep .ant-input-number-lg {
  font-size: 14px;
}

::v-deep .ant-badge-status-text {
  color: rgba(0, 0, 0, 0.85);
}

.space {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-gap: 0 8px;
  margin-bottom: 16px;
}

// ::v-deep .ant-table-tbody>tr>td {
//   border-bottom: none;
// }

// ::v-deep .ant-table-tbody {
//   .ant-table-row:last-of-type td {
//     border-bottom: 1px solid #EFEFEF;
//   }
// }
// #F9F9F9
::v-deep .ant-table {
  color: #131212;
}

::v-deep .ant-table-thead > tr > th {
  background: #F4F5F7;
  border-bottom: none;
  font-size: 14px;
  font-weight: 600;
  color: #131212;
  padding: 12px 16px;
}

// ::v-deep .ant-table-row {
//   // background-color: #f9f9f9;
//   // border-bottom: 1px solid #EFEFEF;
// }

//::v-deep .ant-table-expanded-row {
//  background-color: #F9F9F9;
//
//  // &:hover {
//  //   background-color: #fff;
//  // }
//  td[colspan=
//
//  5 ] {
//  padding-left: 76px;
//}
//}

img.tableImag {
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  margin-right: 8px;
}

::v-deep .ant-badge-status-dot {
  width: 8px;
  height: 8px;
}

.info-card {
  font-size: 16px;
  color: #131212;
  display: flex;
  align-items: center;

  img {
    width: 21px;
    height: 21px;
  }

  span {
    margin-left: 10px;
  }
}

.inner-box {
  position: relative;
  width: 100%;
  // overflow: hidden;
  color: #131212;
  padding-left: 60px;
  box-sizing: border-box;
  display: flex;
  align-items: center;

  // span {
  //   .text-ellipsis-multi(1)
  // }
  .desc {
    .text-ellipsis-multi(1);
    color: #000000D9;
    margin-right: 8px;
  }

  a {
    cursor: pointer;
  }

  .action-btn {
    position: absolute;
    bottom: 0;
    right: 155px;
  }
}

.message-info {
  display: inline-block;
  width: 100% !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #131212;
  box-sizing: border-box;
}

::deep .ant-tooltip-open {
  width: 100%;
  overflow: hidden;
}

.tag {
  display: inline-block;
  width: 40px;
  height: 24px;
  border-radius: 3px;
  line-height: 24px;
  text-align: center;
  font-size: 12px;

  &_1 {
    background: #EE42611A;
    color: #EE4261;
  }

  &_2 {
    background: #E6FAF3;
    color: #06BF7F;
  }
}
</style>
<style>::v-deep .RequirementPool[data-v-19d72bc6]{
  padding: 0px !important;
}</style>
